import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/layout';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import i18n from '@/lang'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: '/home',
        meta: {title: 'common.Home', icon: 'dashboard'},
        children: [
            {
                path: '/home',
                component: () => import('@/views/home'),
                name: 'home',
                meta: {title: 'common.Home', icon: 'dashboard'},
            },
            {
                path: '/personal-center',
                component: () => import('@/views/personalCenter/index'),
                name: 'personalCenter',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/product-center',
                component: () => import('@/views/productCenter/list'),
                name: 'productCenter',
                meta: {title: 'common.ProductCenter', icon: 'dashboard'},
            },
            {
                path: '/product-comparison',
                component: () => import('@/views/productCenter/comparison/index'),
                name: 'productComparison',
                meta: {title: 'ProductComparison', icon: 'dashboard'},
            },
           {
                path: '/channel',
                component: () => import('@/views/channel/index'),
                name: 'channel',
                meta: {title: 'common.channel', icon: 'dashboard'},
            },
            {
                path: '/product-center/detail/:type/:productId',
                component: () => import('@/views/productCenter/detail'),
                name: 'productDetail',
                meta: {
                    title: 'common.ProductDetail',
                    icon: 'dashboard',
                    parent: '/product-center',
                },
            },
            {
                path: '/what-is-a-software-defined-camera',
                component: () => import('@/views/productCenter/sdc/index'),
                name: 'what is a software defined camera',
                meta: {
                    title: 'common.WhatIsaSoftwareDefinedCamera',
                    icon: 'dashboard'
                }
            },
            {
                path: '/sitemap',
                component: () => import('@/views/sitemap/sitemap'),
                name: 'sitemap',
                meta: {
                    title: 'common.ProductDetail',
                    icon: 'dashboard'
                }
            },
            // {
            //     path: '/solution',
            //     component: () => import('@/views/scenarioSolution/list'),
            //     name: 'solutions',
            //     meta: {title: 'common.Solutions', icon: 'dashboard'},
            // },
            // {
            //     path: '/solution/detail',
            //     component: () => import('@/views/scenarioSolution/detail'),
            //     name: 'solutionsDetail',
            //     meta: {
            //         title: 'common.SolutionsDetail',
            //         icon: 'dashboard',
            //         parent: '/solution',
            //     },
            // },
            {
                path: '/news',
                component: () => import('@/views/newsCenter/index'),
                name: 'newsCenter',
                meta: {
                    title: 'common.NewsRoom',
                    icon: 'dashboard'
                },
            },
            {
                path: '/news/:id/detail',
                component: () => import('@/views/newsCenter/detail'),
                name: 'newsDetail',
                meta: {
                    title: 'common.NewsRoomDetail',
                    icon: 'dashboard',
                    parent: '/news',
                },
            },
            {
                path: '/documentation',
                component: () => import('@/views/documentation'),
                name: 'documentation',
                meta: {title: 'common.Documentation', icon: 'dashboard'},
            },
            {
                path: '/support',
                component: () => import('@/views/support'),
                name: 'support',
                meta: {title: 'common.Support', icon: 'dashboard'},
            },
            {
                path: '/tools/:target?',
                component: () => import('@/views/tools/tools'),
                name: 'tools',
                meta: {title: 'common.tools', icon: 'dashboard'},
            },
            {
                path: '/new-product-launch',
                component: () => import('@/views/activity/minisite'),
                name: 'activity',
                meta: {title: 'common.activity', icon: 'dashboard'},
            },
            {
                path: '/about',
                component: () => import('@/views/about'),
                name: 'about',
                meta: {title: 'navbar.about', icon: 'dashboard'},
            },
            {
                path: '/FAQ',
                component: () => import('@/views/faq'),
                name: 'FAQ',
                meta: {title: 'faq.faq', icon: 'dashboard'},
            },
            {
                path: '/FAQ/:id/detail',
                component: () => import('@/views/faq/detail'),
                name: 'FAQDetail',
                meta: {title: 'faq.faq', icon: 'dashboard'},
            },
            {
                path: '/contactus',
                component: () => import('@/views/contactUs/pricingInfo'),
                name: 'ContactUsPricingInfo',
                meta: {title: 'contactUs.contactUsPricingInfo', icon: 'dashboard'},
            },
            {
                path: '/specialist',
                component: () => import('@/views/partners/index'),
                name: 'specialist',
                meta: {title: 'common.forPartners', icon: 'dashboard'},
            },
            {
                path: '/404',
                component: () => import('@/views/errorPage/404'),
                name: '404',
                meta: {title: 'common.login', icon: 'dashboard'},
            },
            {
                path: '/privacy-policy',
                component: () => import('@/views/privacy/PrivacyPolicy'),
                name: 'privacy-policy',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/legal',
                component: () => import('@/views/privacy/TermsOfUse'),
                name: 'legal',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/video-library',
                component: () => import('@/views/videoLibrary/index'),
                name: 'video-library',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/contact',
                component: () => import('@/views/contact/index'),
                name: 'ContactUS',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/learn-register',
                component: () => import('@/views/learnRegister/index'),
                name: 'LearnRegister',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/reseller',
                component: () => import('@/views/reseller/index'),
                name: 'Reseller',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/algorithmstore',
                component: () => import('@/views/algorithmMall/index'),
                name: 'AIgorithmMall',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/algorithm-details-page/:id',
                component: () => import('@/views/algorithmMall/algorithmDetailsPage/index'),
                name: 'AiGorithmDetailsPageIndex',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/app/download', // 这个是摄像头监控app
                component: () => import('@/views/appdownload/index'),
                name: 'AppDownload',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/htmldoc/:id',
                component: () => import('@/views/documentation/htmldoc'),
                name: 'Htmldoc',
                meta: {
                    title: '',
                    icon: 'dashboard',
                },
            },
            {
                path: '/terms',
                component: () => import('@/views/terms/index'),
                name: 'Terms',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/terms/rma',
                component: () => import('@/views/terms/rma/index'),
                name: 'Rma',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/announcement',
                component: () => import('@/views/announcement/list'),
                name: 'AnnouncementList',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/announcement/:id/details',
                component: () => import('@/views/announcement/details'),
                name: 'AnnouncementDetails',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/warrantypolicy',
                component: () => import('@/views/terms/warrantypolicy/index'),
                name: 'Warrantypolicy',
                meta: {title: '', icon: 'dashboard'},
            },
            {
                path: '/dr-list',
                component: () => import('@/views/dr/list'),
                name: 'DrList',
            },
            {
                path: '/deal/:id?',
                component: () => import('@/views/dr/details'),
                name: 'Deal'
            },
            {
                path: '/firmware',
                component: () => import('@/views/firmware/enterPage'),
                name: 'Enter'
            },
            {
                path: '/firmware-version/:id',
                component: () => import('@/views/firmware/version'),
                name: 'Version'
            },
            {
                path: '/firmware-soft/:code',
                component: () => import('@/views/firmware/software'),
                name: 'Software'
            },
            {
                path: '/soft-download',
                component: () => import('@/views/software/index'),
                name: 'SoftPage'
            },
            {
                path: '/soft-download/:id',
                component: () => import('@/views/software/download'),
                name: 'SoftDownload'
            },
            {
                path: '/solution',
                component: () => import('@/views/solution/index'),
                name: 'Solution'
            },
            {
                path: '/solution-details/:id/solution',
                component: () => import('@/views/solution/details'),
                name: 'SolutionDetails'
            },
            {
                path: '/csp-learn',
                component: () => import('@/views/csp/video/filters'),
                name: 'CspLearn'
            },
            {
                path: '/csp-video/:videoId',
                component: () => import('@/views/csp/video/video'),
                name: 'CspLearnVideo'
            },
            {
                path: '/training',
                component: () => import('@/views/training/index'),
                name: 'Training'
            },
            {
                path: '/training-video/:videoId/training',
                component: () => import('@/views/training/video'),
                name: 'TrainingVideo'
            },
            {
                path: '/knowledge',
                component: () => import('@/views/knowledge/index'),
                name: 'KnowledgeIndex',
                meta: {
                    title: 'knowledge',
                    icon: 'dashboard'
                },
            },
            {
                path: '/knowledge/:id/detail',
                component: () => import('@/views/knowledge/detail'),
                name: 'KnowledgeDetail',
                meta: {
                    title: 'KnowledgeDetail',
                    icon: 'dashboard',
                    parent: '/knowledge',
                },
            },
            {
                path: '/holowitswiki/:label?',
                component: () => import('@/views/wiki/nav/index'),
                name: 'WikiNav'
            },
            {
                path: '/wiki',
                component: () => import('@/views/wiki/index'),
                name: 'Wiki'
            },
            {
                path: '/wiki-search',
                component: () => import('@/views/wiki/search/index'),
                name: 'WikiSearch'
            },
            {
                path: '/case',
                component: () => import('@/views/case/index'),
                name: 'Case'
            },
            {
                path: '/case/:id/detail',
                component: () => import('@/views/case/details'),
                name: 'CaseDetail'
            },
            {
                path: '/training-course',
                component: () => import('@/views/trainingCourse/index'),
                name: 'TrainingCourse'
            },
            {
                path: '/my-learn',
                component: () => import('@/views/myLearn/index'),
                name: 'MyLearn'
            },
            {
                path: '/my-learn/:id/details',
                component: () => import('@/views/myLearn/details/index'),
                name: 'MyLearnDetails'
            },
            {
                path: '/product-certification',
                component: () => import('@/views/certification/index'),
                name: 'Certification'
            },
            // {
            //     path: '/cloud',
            //     component: () => import('@/views/threeCloud/index'),
            //     name: 'ThreeCloud'
            // },
        ],
    },
    {
        path: '/registration/:companyName/:country',
        component: () => import('@/views/resellerQr/index'),
        name: 'resellerQr'
    },
    // {
    //     path: '/morocco-register',
    //     component: () => import('@/views/bangkokRegister/index'),
    //     name: 'bangkokRegister'
    // },
    // {
    //     path: '/mexico-register',
    //     component: () => import('@/views/bangkokRegister/index2'),
    //     name: 'mexicoRegister'
    // },
    // {
    //     path: '/agadir-register',
    //     component: () => import('@/views/bangkokRegister/index3'),
    //     name: 'agadirRegister'
    // },
    // {
    //     path: '/jakarta-register',
    //     component: () => import('@/views/bangkokRegister/index3'),
    //     name: 'jakartaRegister'
    // },
    // {
    //     path: '/mexico-city-register',
    //     component: () => import('@/views/bangkokRegister/index3'),
    //     name: 'mexicoCityRegister'
    // },
    {
        path: '/shanghai-internal',
        component: () => import('@/views/bangkokRegister/shanghaiInternal'),
        name: 'shanghaiInternal'
    },
    {
        path: '/shanghai-partner',
        component: () => import('@/views/bangkokRegister/shanghaiPartner'),
        name: 'shanghaiPartner'
    },
    {
        path: '/shanghai-eco',
        component: () => import('@/views/bangkokRegister/shanghaiEco'),
        name: 'shanghaiEco'
    },
    {
        path: '/intersec2025',
        component: () => import('@/views/bangkokRegister/intersec2025'),
        name: 'intersec2025'
    },
    {
        path: '/customer-voice',
        component: () => import('@/views/customerVoice/index'),
        name: 'customerVoice'
    },
    {
        path: '/login',
        component: () => import('@/views/login/login'),
        name: 'login',
        meta: {title: 'common.login', icon: 'dashboard'},
    },
    {
        path: '/changePassword',
        component: () => import('@/views/login/changePwd'),
        name: 'password',
        meta: {title: 'common.changePwd', icon: 'dashboard'},
    },
    {
        path: '/forgetPassword',
        component: () => import('@/views/login/forgetPwd'),
        name: 'forgetPassword',
        meta: {title: 'common.resetPassword', icon: 'dashboard'},
    },
    {
        path: '/resetPassword',
        component: () => import('@/views/login/resetPwd'),
        name: 'resetPassword',
        meta: {title: 'common.resetPassword', icon: 'dashboard'},
    },
    {
        path: '/register',
        component: () => import('@/views/login/register'),
        name: 'register',
        meta: {title: 'common.register', icon: 'dashboard'},
    },
    {
        path: '*', // 页面不存在的情况下会跳到404页面
        redirect: '/404',
        name: 'notFound',
        hidden: true
    },
    {
        path: '/holoz-app-download', // 这个是holoz的app下载判断是哪个系统的
        component: () => import('@/views/appdownload/AppDownload2'),
        name: 'HolozApp',
        meta: {title: '', icon: 'dashboard'},
    },
    {
        path: '/holowitsapp', // 这个是摄像头监控app下载判断是哪个系统的
        component: () => import('@/views/appdownload/AppDownload'),
        name: 'Holowitsapp',
        meta: {title: '', icon: 'dashboard'},
    },
    {
        path: '/dr-privacy-policy',
        component: () => import('@/views/dr/privacyPolicy'),
        name: 'PrivacyPolicy',
    },
    {
        path: '/policy-S200app',
        component: () => import('@/views/policy/s200app'),
        name: 'S200'
    },
    {
        path: '/brazil-privacy-notice',
        component: () => import('@/views/policy/s200BrazilPrivacy'),
        name: 'S200Brazil'
    },
    {
        path: '/instructions/:product',
        component: () => import('@/views/m/instructions'),
        name: 'Instructions'
    },
    // {
    //     path: '/cloud/:id/details',
    //     component: () => import('@/views/threeCloud/details'),
    //     name: 'ThreeCloudDetails'
    // },
];

console.log(" i18n.locale: ", i18n.locale)

console.log("process.env.BASE_URL: ",process.env.BASE_URL)


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL + (i18n.locale=='en'?'':i18n.locale),
    routes,
    scrollBehavior: function (to, from, savedPosition) {
        // savedPosition 会在你使用浏览器前进或后退按钮时候生效
        // 这个跟你使用 router.go() 或 router.back() 效果一致
        // 这也是为什么我在 tab 栏结构中放入了一个 点击回退 的按钮
        if (savedPosition) {
            return savedPosition;
        } else {
            // 如果不是通过上述行为切换组件，就会让页面回到顶部
            return {x: 0, y: 0};
        }
    },
});

router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router;
